@font-face {
	font-family: 'Encode Sans Regular';
	src: url("fonts/Encode_Sans/static/EncodeSans/EncodeSans-Regular.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Encode Sans Light';
	src: url("fonts/Encode_Sans/static/EncodeSans/EncodeSans-Light.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Encode Sans Bold';
	src: url("fonts/Encode_Sans/static/EncodeSans/EncodeSans-Bold.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Encode Sans SemiBold';
	src: url("fonts/Encode_Sans/static/EncodeSans/EncodeSans-SemiBold.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Encode Sans Medium';
	src: url("fonts/Encode_Sans/static/EncodeSans_SemiExpanded/EncodeSans_SemiExpanded-Medium.ttf") format('truetype');
  }



  

  @font-face {
	font-family: 'Roboto Regular';
	src: url("fonts/Roboto/Roboto-Regular.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Roboto Light';
	src: url("fonts/Roboto/Roboto-Light.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Roboto Bold';
	src: url("fonts/Roboto/Roboto-Bold.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Roboto Medium';
	src: url("fonts/Roboto/Roboto-Medium.ttf") format('truetype');
  }

  @font-face {
	font-family: 'Roboto Italic';
	src: url("fonts/Roboto/Roboto-Italic.ttf") format('truetype');
  }



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Encode Sans',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
