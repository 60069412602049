.App {
  text-align: center;

  height:100vh;
}

.footer-link:hover{
	color: #ade4ff	;
}

.footer-link{
	color: white;
	text-decoration: none;
}


.footer{
	background-color: #01548a;
}

.footer-text{
	font-family: "Encode Sans Regular";
	color: white;
}

a:hover{
	color: #37BBED !important;
}

a{
	font-family: 'Encode Sans Regular';
	color: black;
}

.bodytext{
	margin-top: 3rem;
}
.longtext{
	font-family: 'Roboto Regular';
	line-height: 2em;
	text-align: justify;
}



/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1200px) { 
	#CronogramaContainer.container-fluid {
		
		background-color: white;
		height: 100vh;	
		
		}

	#NovedadesContainer.container-fluid {
		
		background-color: white;
		height:max-content;	

		}


	#AboutContainer.container-fluid {
		
		background-color: white;
		height: max-content;	

		}

	#MainContainer.container-fluid {
		background-image: url("../assets/images/image-largetd2.jpg");
	
		
		background-size: 100% 100vh;
		background-repeat: no-repeat;
		height: 100vh;	

		}

		.box{
			height: 100vh;	
			filter: blur(0px);
			
			box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
			filter: blur(1px);
			-webkit-filter: blur(1px);
			-webkit-filter: blur(0px);
			padding: 4rem;

			align-items: left;
			vertical-align: middle;

		
		}


		hr{
			opacity: 0.8 !important;
			height: 2px !important;
		}
		
		h1{
			font-family: 'Encode Sans Medium';
			text-shadow: 2px 2px 5px black;
			text-align: start;
			font-size: 64px;
		}

		h2{
			font-family: 'Encode Sans Bold';
			text-align: start;
			color: black;
			font-size: 36px;
		}


		h3{
			font-family: 'Encode Sans Regular';
			text-shadow: 2px 2px 5px black;
			text-align: start;
			font-size: 28px;
		}

		h4{
			font-family: 'Encode Sans Medium';
			color: #0991d1 ;
			text-align: start;
			font-size: 20px;
		}

		h5{
			font-family: 'Encode Sans Regular';
			color: #37BBED;
			text-align: start;
			font-size: 20px;
		}

		h1,h3,hr{
		
			color:white;
			z-index: auto;
		}

}
/* 
medium devices */
@media (max-width: 1199.98px) { 

	#CronogramaContainer.container-fluid {
		
		background-color: white;
		height: 100vh;	
		
		}

#NovedadesContainer.container-fluid {
		
		background-color: white;
		height: max-content;	

		}


	#AboutContainer.container-fluid {
		
		background-color: white;
		height: max-content;	

		}

	#MainContainer.container-fluid {

		background-image: url("../assets/images/image-medium-td.jpg");
		background-size: 100vw 100vh;
		width: 100vw;
		height: 100vh ;
	}

	.box{
		height: 100vh;	
		filter: blur(0px);
		
		box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
		filter: blur(1px);
		-webkit-filter: blur(1px);
		-webkit-filter: blur(0px);
		padding: 4rem;
	}

	hr{
		opacity: 0.8 !important;
		height: 2px !important;
	}
	
	h1{
		font-family: 'Encode Sans Medium';
		text-shadow: 2px 2px 5px black;
		text-align: start;
		font-size: 64px;
	}

	h2{
		font-family: 'Encode Sans Bold';
		text-align: start;
		color: black;
		font-size: 36px;
	}

	h3{
		font-family: 'Encode Sans Regular';
		text-shadow: 2px 2px 5px black;
		text-align: start;
		font-size: 28px;
	}

	h4{
		font-family: 'Encode Sans Medium';
		color: #0991d1 ;
		text-align: start;
		font-size: 20px;
	}

	h5{
		font-family: 'Encode Sans Regular';
		color: #37BBED;
		text-align: start;
		font-size: 20px;
	}
	h1,h3,hr{
	
		color:white;
		z-index: auto;
	}
	
	
}

/* small devices */
	@media (max-width: 991.98px) { 

		#CronogramaContainer.container-fluid {
		
			background-color: white;
			height: max-content;	
			
			}


			#NovedadesContainer.container-fluid {
		
		background-color: white;
		height: max-content;	

		}

		#AboutContainer.container-fluid {
		
			background-color: white;
			height: max-content;	
	
			}
		
		#MainContainer.container-fluid {

			background-image: url("../assets/images/image-mobile-td2.jpg");
			background-size: 100vw 100vh;
			
			height: 100vh ;
		}

		.box{
			height: 100vh;	
			filter: blur(0px);
			
			box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
			filter: blur(1px);
			-webkit-filter: blur(1px);
			-webkit-filter: blur(0px);
			padding: 2rem;
		}

		hr{
			opacity: 0.8 !important;
			height: 1px !important;
		}
		
		h1{
			font-family: 'Encode Sans Medium';
			text-shadow: 2px 2px 5px black;
			text-align: start;
			font-size: 36px;
		}

		h2{
			font-family: 'Encode Sans Bold';
			text-align: start;
			color: black;
			font-size: 28px;
		}

		h3{
			font-family: 'Encode Sans Regular';
			text-shadow: 2px 2px 5px black;
			text-align: start;
			font-size: 24px;
		}

		h4{
			font-family: 'Encode Sans Medium';
			color: #0991d1 ;
			text-align: start;
			font-size: 20px;
		}

		h5{
			font-family: 'Encode Sans Regular';
			color: #37BBED;
			text-align: start;
			font-size: 18px;
		}

		h1,h3,hr{
		
			color:white;
			z-index: auto;
		}
	} 
 
	
	




/* html,body{

	height: 100%;
} */

