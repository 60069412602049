/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1200px) { 
	img{
		height: 56px;	
		}

}
/* 
medium devices */
@media (max-width: 1199.98px) { 

	img{
		height: 56px;	
		}
	
	
}

/* small devices */
	@media (max-width: 991.98px) { 
		
		img{
			height: 32px;	
			}

	} 
 