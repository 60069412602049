ul{    
	white-space: pre-wrap;
}

.imgCard {
	height: 100%;
	
}

.icono {
	float:left;
	height: 32px;
	width: 32px;
}

.descripcion{
	margin-top: 2px;
	margin-left: 48px;
}