/* a:hover{
	color:#193456 !important;
} */
.event:hover{
	background-color: #e7e7e7	;
}

.event{
	background-color: #f8f8f8;
	border-radius: 0%;
	border-color: #f8f8f8;

}

a.sinUnderline:hover{
	color:black !important;
}
a.sinUnderline{
	text-decoration: none!important;
	/* pointer-events: none; */
}

.card-text{
	font-family: "Roboto Regular";

}

h2{
	color:#193456;

}


.badge{
	font-family: "Encode Sans Bold";
	background-color: #37BBED;
	color: white;

}