.img-galeria{
	height: 200px;
}

.img-galeria-modal{
	height: 400px;
	width: fit-content;
}

.modal-dialog{
	max-width: max-content !important;
}
.foto-card:hover{
	background-color: #e7e7e7	;
	font-family: "Roboto Regular";
	
}